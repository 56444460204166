<template>
  <v-main>
    <v-row gutters="2" class="pt-0 pb-0 mb-2" justify="end">
      <v-col cols="12">
        <Buttons
          :btnCancel="false"
          :textSave="$t('PACKAGES.NEW')"
          v-on:save="addPackage"
        />
      </v-col>
    </v-row>
    <v-row gutters="2" class="pt-0 pb-0">
      <v-col cols="12" md="12" class="pb-0 pb-0">
        <v-card>
          <v-card-text>
            <PackagesTable :data-packages="productPackages"></PackagesTable>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import { mapGetters } from "vuex";

import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_ALL_PRODUCT_PACKAGES } from "@/core/services/store/packages/product_package.module";

import Buttons from "@/view/content/components/Buttons";
import PackagesTable from "@/view/pages/packages/components/Table";

export default {
  name: "packages",
  components: { Buttons, PackagesTable },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("PACKAGES.TITLE") },
    ]);
    this.$store.dispatch(GET_ALL_PRODUCT_PACKAGES);
  },
  computed: {
    ...mapGetters(["productPackages"]),
  },
  methods: {
    addPackage() {
      this.$router.push({ name: "packages-new" });
    },
  },
};
</script>
