<template>
  <div>
    <v-row gutters="2" justify="end">
      <v-col cols="12" md="4">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          outlined
          dense
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row gutters="2" justify="center">
      <v-col cols="12" md="12">
        <v-data-table :headers="headers" :items="dataPackages" :search="search">
          <template v-slot:item.name="{ item }">
            <router-link
              :to="{
                path: `/packages/edit/${item.productId}`,
              }"
            >
              {{ item.name }}
            </router-link>
          </template>
          <template v-slot:item.created_at="{ item }">
            <span v-if="item.created_at">
              {{ item.created_at | moment(campus.date_format) }}
            </span>
          </template>
          <template v-slot:item.updated_at="{ item }">
            <span v-if="item.updated_at">
              {{ item.updated_at | moment(campus.date_format) }}
            </span>
          </template>
          <template v-slot:item.ACTIONS="{ item }">
            <v-icon @click="deleteRecord(item)">delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="isShowDialogDelete" max-width="350">
      <v-card>
        <v-card-title class="headline">
          {{ $t("MESSAGE.DELETE.MODAL_TITLE") }}
        </v-card-title>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color="red darken-1" text @click="isShowDialogDelete = false">
            {{ $t("MESSAGE.DELETE.MODAL_NO") }}
          </v-btn>

          <v-btn color="primary darken-1" text @click="deletePackage()">
            {{ $t("MESSAGE.DELETE.MODAL_YES") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  props: {
    dataPackages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      search: "",
      isShowDialogDelete: false,
      deleteId: null,
      headers: [
        {
          text: this.$t("PACKAGES.TABLE.NAME"),
          align: "left",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("PACKAGES.TABLE.CREATED"),
          align: "left",
          sortable: true,
          value: "created_at",
        },
        {
          text: this.$t("PACKAGES.TABLE.LAST_UPDATED"),
          align: "left",
          sortable: true,
          value: "updated_at",
        },
        {
          text: this.$t("PACKAGES.TABLE.ACTIONS"),
          align: "center",
          sortable: false,
          value: "ACTIONS",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["productPackages", "campus"]),
  },
  methods: {
    ...mapActions(["deleteProductPackage"]),
    deleteRecord(item) {
      this.isShowDialogDelete = true;
      this.deleteId = item.productId;
    },
    deletePackage() {
      this.isShowDialogDelete = false;
      this.deleteProductPackage(this.deleteId);
    },
  },
};
</script>
